/* This is needed for the wicg-inert polyfill */

[inert] {
  pointer-events: none;
  cursor: default;
}

[inert],
[inert] * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
